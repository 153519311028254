// const primary = "hsla(219, 34%, 34%, 1.0)"

//const primary = "hsla(219, 45%, 44%, 1.0)"
// const primary = '#2e8c1c'
//
//const primary = '#326331'

//const primary = 'rgb(68, 129, 68)'

const primary = '#438e46'

const primaryLite = 'rgb(226, 225, 213)'
//const primaryLite = 'hsla(219, 83%, 79%, 1.0)'
const accent = 'hsla(219, 56%, 69%, 1.0)'
const accentTrans = 'hsla(219, 56%, 69%, 0.65)'

// const offWhite = "hsla(190, 100%, 96%,1)"
// rgb(240, 240, 240)
//const offWhite = 'hsla(190, 100%, 96%, 1.0)'
//const offWhite = '#e5e9f0'
const black = 'hsla(345, 6%, 13%, 1.0)'
const header = 'hsla(348, 4%, 24%, 1.0)'
const text = black

export const theme = {
  primary,
  accent,
  accentTrans,
  primaryLite,
  black,
  text,
  header,
  //offWhite,
}

// const primary2 = "hsla(151,86%,34%,1)"
// const accent2 = "hsla(151,62%,79%,1)"
// const primaryLite2 = "hsla(151,50%,82%,1)"
// const offWhite2 = '#dadada'
// const black2 = "hsl(151,50%,0%)"
